/**
 * Copyright Jiaqi Liu
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const videos = [
  {
    title: "Generation War - Party",
    duration: "1:49",
    transcript: (
      <div>
        Wir waren fünf. Fünf Freunde. <br />
        <br />
        - Sag mal, seit wann hast du so ein schickes Fahrrad? <br />
        - Geklaut. <br />
        <br />
        Wir wollten noch einmal gebührend voneinander Abschied nehmen, bevor sich unsere Wege trennten. <br />
        <br />
        - Auf uns! <br />
        - Auf uns! <br />
        - Auf uns! <br />
        <br />
        Wir waren im selben Viertel aufgewachsen und kannten uns, seit wir Kinder waren. Wir hätten nicht
        unterschiedlicher sein können. Und doch waren wir seit der Zeit Freunde, die zusammenhielten. Charly, unser
        Nesthäkchen, will seit Kriegsbeginn ihren Dienst fürs Vaterland leisten. Sie wird als Krankenschwester in einem
        Lazarett arbeiten, das hinter der Front meiner Einheit folgt. Viktor Goldstein. Sein Vater war Unteroffizier im
        Ersten Weltkrieg und später ein angesehener Schneider. Viktor sollte das Geschäft übernehmen, bevor es in der
        Reichskristallnacht in Scherben zerfiel. Das ist seine Freundin Greta. Die beiden sind unzertrennlich. Greta
        interessiert es nicht, dass ihre Beziehung zur Victor als Rassenschande gilt. Wir alle glauben, dass sie mal ein
        Star werden wird. Die neue Marlene Dietrich. Mein kleiner Bruder Friedhelm. Der Bücherwurm, der Rimbaud und
        Jünger liebt. Ich bewundere ihn für seine Klugheit und seinen scharfen Witz. Für ihn ist es das erste Mal. Und
        hier bin ich, Wilhelm Winter. Leutnant der Windhund-Kompanie. Einsatz in Polen und Frankreich. Und morgen gent's
        Richtung Russland. <br />
        <br />
        - Der Krieg wird schon noch einen Mann aus ihm machen. <br />
        - Glaub mir, der Krieg wird nur das Schlechteste in uns zum Vorschein bringen. <br />
        - Ich würde gern mitmachen, wie mein Vater 1913, aber wenn ihr verliert, heißt es wieder wir waren schon. <br />
        - Das ist eben kein Land für Schwächlinge und Volksfremde. <br />
        - Jeder muss Opfer bringen. <br />
      </div>
    ),
    youTubeVideoId: "IQU-KXP6lzo",
  },
  {
    title: "Generation War - Party Pray",
    duration: "1:02",
    transcript: (
      <div>
        - Greta, wir müssen los. <br />
        - Jetzt? <br />
        - Charly wollte noch ... <br />
        - Das kann warten. <br />
        - Spätestens Weihnachten ist der Krieg vorbei. <br />
        - Ja, lass uns schwören. Weihnachten sind wir alle wieder hier. Hier in diesem Raum. Lebend. <br />
        - Weihnachten in Berlin. <br />
        - Weihnachten in Berlin. <br />
        - Weihnachten in Berlin. <br />
        - Wartet, ich mach noch ein Foto. Na los, stell dich dann dort nach hinten ans Regal. Na, kommt. <br />
        - Hier? <br />
        - Ja, das ist gut. Nimm einen Stuhl, einen Hocker, irgendwas. Friedhelm, komm nach vorne. Nimm Greta. <br />
        <br />
        Wir waren fünf Freunde. Wir waren jung und wir wussten, dass uns die Zukunft gehören würde. Die ganze Welt lag
        vor uns. Wir mussten nur zugreifen. Wir waren unsterblich. Wir sollten es bald besser wissen.
      </div>
    ),
    youTubeVideoId: "ZjWcMWGIXaY",
  },
  {
    title: "Generation War - Field",
    duration: "1:30",
    transcript: (
      <div>
        <p>
          10. Juli 1941. Dies wird ein anderer Krieg werden als alle zuvor. Es ist eine Lust vorzustoßen. Der Rausch der
          Geschwindigkeit. Immer weiter und weiter und weiter.
        </p>
        <br />
        <p>
          Die Endlosigkeit des Raumes. Landschaften, wie wir sie noch nie gesehen hatten. Felder bis zum Horizont. Der
          Himmel so weit und blau, als wollte Gott uns mit der Schönheit seiner Schöpfung blenden.
        </p>
        <br />
        <p>
          - Ist das nicht großartig? <br />
          - Im nächsten Jahr werden hier überall deutsche Höfe stehen. <br />
        </p>
        <br />
        <p>
          Meine Männer schlagen sich tadellos. Bei jedem Einsatz sind sie ganz vorne dabei. Alle bis auf Friedhelm, den
          alle für einen Feigling halten, der mich jeden Tag provoziert und für den ich mich schäme.
        </p>
        <br />
        - Was meint ihr, wie weit ist es noch bis Moskau? <br />
        - So um die 700 Kilometer. <br />
        - Na, lasst uns durchfahren. Nicht umsonst sehen wir die Windhunde. <br />
        - Frühstück auf dem Roten Platz mit Wodka und Kaviar. <br />
        - Und wie willst du dann ein Held werden? <br />
        - Kein einziger Russen erwischt, der will schon den Krieg gewonnen haben. <br />
        - Genau. <br />
        - Na, wenn sich die Russen immer so Vorteile ergeben, soll ich Gefangene abknallen. <br />
        - Winter hier will mir denen ja liebe Gedichte vortragen. <br />
        - Koch, wie viel hast du? <br />
        - Koch hat bestimmt schon zehn. <br />
        - Dann schreibt Freitag seiner Frau morgen 'ne Karte vom Roten Platz. <br />
      </div>
    ),
    youTubeVideoId: "QlZZBZ8Lnh4",
  },
  {
    title: "Generation War - Receiving Order",
    duration: "0:47",
    transcript: (
      <div>
        - Hauptmann Feigl. <br />
        - Wilhelm. Sechs Kilometer nordöstlich. Widerstandsnest in einer Fabrik. Wir wollen keine Zeit verlieren. Die
        Kompanie umgeht sie und setzt ihren Weg fort. Die nehmen ihre besten Männer und räuchern es aus. Nach
        erfolgreicher Erledigung schlossen sie zu uns. Verstanden?
        <br />
        - Jawohl, Hauptmann. <br />
        - Viel Glück. <br />
        <br />
        - Es gibt ein Fabrikgelände, nordöstlich von hier zu räumen. Da kommen wir uns. Höring Fleck, Mann nach
        Kupfkopfka. Wir treffen uns dann. Verstanden? <br />
        - Jawohl, Herr Leutnant. <br />
        - Dann los! <br />
        <br />
        - Jetzt gibt's was vor die Flinte, Mädels. Verstanden? <br />
        - Jawohl, Herr Hauptoffizier. <br />- Na dann, verhauen wir mal den Iwan ein bisschen, ne?
      </div>
    ),
    youTubeVideoId: "jRZzMVpw81A",
  },
  {
    title: "Generation War - Operation Aftermaths",
    duration: "1:12",
    transcript: (
      <div>
        - Verdammtes jüdisches Bolschewistenschwein! <br />
        - Schneider, reiß dich zusammen! <br />
        - Schmidt könnte noch leben. <br />
        - Aber er ... <br />
        - Nichts aber! Hol das MG von oben. <br />
        - Jawohl, Herr Leutnant. <br />
        - Wo ist Freitag? <br />
        - Der Sani versorgt ihn gerade. <br />
        - Bringen Sie die Männer zum LKW. <br />
        - Aber, aber er ist einer von diesen Kommissaren! <br />
        - Er wird verhört, verstanden? <br />
        - Abführen! <br />
        - Ihr habt's gehört, Männer. <br />
        - Verbinsky. <br />
        - Kiek mal! <br />
        - Das ist für den Küchenbullen. Ich hab ein Rezept für Fleischsuppe von meiner Großmutter. <br />
        - Komm, Schnitzel. <br />- Komm. Komm, Schnitzel.
      </div>
    ),
    googleDriveVideoId: "1XV66QYsGJvoUYfMviFE-OmvLKZgw-jbH",
  },
  {
    title: "Generation War - The Talk",
    duration: "1:56",
    transcript: (
      <div>
        - Die kommunistischen Genossen scheinen die Überlegenheit der arischen Rasse sehr schnell zu begreifen <br />
        - Geschenk vom Führer. <br />
        - Hier, das ist nur die Vorsuppe. Nachher bringt der Küchenbruder noch Schnitzel. Und für den Rest vom Kalb, wir
        haben Schnittchen. <br />
        <br />
        - Es gibt Suppe, Koch. <br />
        - Jawohl, Herr Leutnant. <br />
        - Friedhelm. <br />
        <br />
        - Was soll das? <br />
        - Was? Hab ich vergessen zu salutieren, Herr Leutnant? <br />
        - Du weißt genau, was! <br />
        - Du bist der Einzige, der sich nie freiwillig meldet. <br />
        - Im Kampf gegen das internationale Finanzjudentum, ja? Ich dachte, es genügt, einen Helden in der Familie zu
        haben. <br />
        - Ich bin hier nicht dein Bruder, sondern dein Vorgesetzter. <br />- Friedhelm, du weißt, wie sie über dich
        denken. Vor Vater konnte ich dich schützen, das kann ich hier nicht. <br />- Darum habe ich dich nie gebeten.
        Weißt du was, wenn du mich in einem Trupp haben willst, dann teile mich ein. <br />
        <br />
        - Männer, wir trinken ein letztes Mal auf Schmidt. <br />
        - So Winter, kannst du auch eine Kerbe in den Kolben schneiden. Wenn du wieder einen Abschuss brauchst, leihst
        du dem Gewehr einem von uns. <br />
        <br />
        - Habt ihr was von Freitag gehört? <br />
        - Er ist im Lazarett. <br />
      </div>
    ),
    youTubeVideoId: "1oCOGixdYms",
  },
  {
    title: "Generation War - Order for Execution",
    duration: "1:01",
    transcript: (
      <div>
        - Saubere Aktion, Leutnant. <br />
        - Vielen Dank, Herr Hauptmann. <br />
        - Wenn Sie wollen, dass ich Schmitz' Eltern benachrichtigen... <br />
        - Das tue ich gerade. Der russische Kommissar ist verhört worden. Vollkommen fanatisch. Sagt, dass wir ihn
        niemals gewinnen werden. Er kann jetzt erschossen werden. <br />
        - Aber der Mann ist Kriegsgefangener. <br />
        - Sie kennen den Befehl. <br />- Die Sowjets haben die Genfer Konvention nicht unterschrieben. Die Kommissare
        sind sofort zu exekutieren. Das ist kein normaler Krieg, sondern eine Weltanschauung. Tun Sie es abseits, sodass
        die Truppe nicht verroht.
      </div>
    ),
    youTubeVideoId: "GXRZK0kDvMo",
  },
  {
    title: "Generation War - Antagonizing Conversation",
    duration: "2:14",
    transcript: (
      <div>
        - Stimmt es, dass der Leutnant den Gefangenen exekutieren musste? <br />
        - Ja, das stimmt. <br />
        - Ich hoffe nur, dass Stalin noch ein wenig durchhält. Stellt euch vor, der Russe kapituliert morgen. Dann
        kommen wir doch nie nach Moskau. Alle wären sie nach Hause gekommen mit schicken Uniformen mit Orden und Narben.
        Die Mädels hätten verrückt gespielt. Und ich? Ich wäre nur ein stinknormaler Zivilist. Ist doch so, oder? <br />
        - Mein Alter war sein Leben lang keine zehn Kilometer von seinem Hof getan. Und ich bin jetzt hier, tausend
        entfernt. Und wisst ihr warum? Der Führer hat's gesagt. Wenn der Krieg gewonnen ist, gibt es hier im Osten Land
        für jeden von uns. Und ich bleib hier. Ich such mir eine Frau und bleib hier. <br />
        - Wird gut gedüngt sein mit unserem Blut. <br />
        - Nicht mit deinem, Winter. Aber du schlau genug bist, den Kopf einzuziehen, sobald der Ivan einen lässt. <br />
        - Ich will dir doch einfach nur den Vortritt lassen, Schneider. Wo du so scharf aufs Eke 1 bist, hm? <br />
        - Was? Was hast du gesagt? <br />
        - [Soldat] Achtung! <br />
        - Macht Schluss für heute. Wir brechen um sechs auf. Es wird ein harter Tag morgen. Gute Nacht. <br />
        - Ab in die Falle, Männer. Morgen um sechs geht's los. <br />
        - Scheiße, das ist mit dem Leutnant. <br />
        - Was scheiße, Koch, er hat's für uns getan. <br />
        - Nicht für mich. <br />
        - Sonst hätte es einer von uns tun müssen. <br />
        - Nein, das hätten wir nicht. <br />
        - Denkst du, der Russe würde es anders mit uns machen? <br />
        - Vielleicht ist er gerade dabei, von uns zu lernen. <br />
      </div>
    ),
    youTubeVideoId: "OTMghAzufw0",
  },
  {
    title: "Generation War - SS Officer Executing Jewish Girl",
    duration: "4:09",
    transcript: (
      <div>
        19. September 1941. Im Kesselschlachten nehmen wir die Russen zu Hunderttausenden gefangen. Doch sie geben immer
        noch nicht auf. Kämpfen um jedes Haus. Die letzten Zuckungen eines Kolosses, der kurz davor steht zu fallen. Ich
        bin immer noch optimistisch, dass wir Weihnachten wieder zu Hause sein werden. Und doch, es ist ein anderer
        Krieg, als ich dachte. <br />
        <br />
        - Ich habe Meldung, dass der Sicherheitsdienst hier eine Säuberungsaktion gegen Partisanen durchführt. Der
        Befehl lautet, keiner kommt rein, keiner kommt raus. <br />
        - Was machen wir, wenn jemand durchführt? <br />
        - Erschießen. <br />
        - Schießt ganz gern, oder? <br />
        - Als ich sieben war, ist unser Vater arbeitslos geworden. Da sind wir in die Wälder. Jagen. Erst als der Führer
        kam, hat Vater wieder Arbeit bekommen. <br />
        - Da sind sie ja endlich. <br />
        - Hilfspolizei. Ukrainer. <br />
        <br />
        [aufgeregtes Stimmengewirr] <br />
        <br />
        - Da sind ja auch Kinder dabei. Die dürfen keine Zivilisten töten. <br />
        <br />
        [dramatische Musik] <br />
        <br />
        - Hey! <br />
        - Stoj! Lass sie los! Was heißt sofort? <br />
        - Sejtschass. <br />
        - Sejtschass! <br />
        - Was ist hier los? <br />
        - Loslassen! <br />
        - Otpusti. <br />
        - Otpusti! <br />
        - Herr Sturmbannführer. <br />
        - Herr Leutnant. <br />
        - Das Mädchen gehört dem Sicherheitsdienst. <br />
        - Das Mädchen untersteht, wie alle Zivilisten im Kampfgebiet der Wehrmacht. <br />
        - Das ist keine Zivilistin. Das ist eine Jüdin. Befehl ist Befehl. <br />
        - Ihre Befehle lauten, ukrainischen Mob anzuführen. Wie ehrenvoll für einen deutschen Offizier. Das Mädchen
        bleibt <br />
        in Ihrer Verantwortung, bis sich die Sache geregelt hat. <br />
        - Zu Befehl. <br />
        - Wie Sie wünschen, Leutnant. <br />
        - Herr Sturmbannführer. <br />
      </div>
    ),
    youTubeVideoId: "fALweo6p318",
  },
  {
    title: "Generation War - Post-Talk on Executing Jewish Girl",
    duration: "1:04",
    transcript: (
      <div>
        - Leutnant Winter. Auf ein Wort. Sind Sie wahnsinnig? Ein Sturmbannführer des Sicherheitsdienstes. <br />
        - Er hat vor meinen Augen ein Kind exekutiert. <br />
        - Wo der Jude ist, ist der Partisan. Wir führen einen Krieg. <br />
        - Aber das ist doch kein Krieg. <br />
        - Doch. Ein neuer. Im Namen des Führers. <br />
        - Wenn wir den verlieren, wird uns nicht mal mehr Gott retten. Und ganz sicher nicht der Führer. unter keinen
        Umständen verlieren. <br />
        - Sie sind mein bester Mann. Sie können es noch sehr weit bringen. Ruinieren Sie nicht Ihre Karriere. Wilhelm.
        Wir sollten uns von der Welt verabschieden, wie wir sie kennen. <br />
      </div>
    ),
    youTubeVideoId: "jEoaHOF-F5c",
  },
  {
    title: "Generation War - Aftermath of Executing Jewish Girl",
    duration: "0:56",
    transcript: (
      <div>
        - [Koch] Sie war höchstens elf. Es geht um die Reinheit unserer Rasse. Die Juden verderben das Blut der
        Deutschen. <br />
        - Halt die Fresse, Schneider. <br />
        - Steht schon in "Mein Kampf". <br />
        - Du kannst lesen? <br />
        - [stottert] Wir verteidigen unser Vaterland. <br />
        - Was denkst du, machen die da drüben? Genau das Gleiche. <br />
        - Herr Sturmbannführer. <br />
        - Leutnant. Entschuldigung wegen der Schweinerei vorhin. War nicht nötig gewesen. Aber das jüdische Blut ist so
        tückisch, es spritzt überall hin. Unberechenbar. Und deswegen sind die Juden so gefährlich. <br />
      </div>
    ),
    youTubeVideoId: "9w5pW2oYPMU",
  },
];
