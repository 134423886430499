// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.movieList {
  border-radius: 2rem;
  -webkit-mask-image: linear-gradient(to top, black 0%, black 100%),
    linear-gradient(to top, transparent 0%, black 100%), linear-gradient(to right, transparent 0%, black 100%),
    linear-gradient(to bottom, transparent 0%, black 100%), linear-gradient(to left, transparent 0%, black 100%);
  -webkit-mask-position: center, top, right, bottom, left;
  -webkit-mask-size:
    100% 100%,
    100% 10px,
    10px 100%,
    100% 10px,
    10px 100%;
  -webkit-mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  -webkit-mask-composite: source-out, source-over, source-over, source-over;
  mask-image: linear-gradient(to top, black 0%, black 100%), linear-gradient(to top, transparent 0%, black 100%),
    linear-gradient(to right, transparent 0%, black 100%), linear-gradient(to bottom, transparent 0%, black 100%),
    linear-gradient(to left, transparent 0%, black 100%);
  mask-position: center, top, right, bottom, left;
  mask-size:
    100% 100%,
    100% 10px,
    10px 100%,
    100% 10px,
    10px 100%;
  mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  mask-composite: subtract, add, add, add;
}

.movieList img {
  height: 100%;
  width: 100%;
  max-height: 30vh;
}
`, "",{"version":3,"sources":["webpack://./packages/vk4501-app/src/components/MovieList.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB;;gHAE8G;EAC9G,uDAAuD;EACvD;;;;;aAKW;EACX,0EAA0E;EAC1E,yEAAyE;EACzE;;wDAEsD;EACtD,+CAA+C;EAC/C;;;;;aAKW;EACX,kEAAkE;EAClE,uCAAuC;AACzC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".movieList {\n  border-radius: 2rem;\n  -webkit-mask-image: linear-gradient(to top, black 0%, black 100%),\n    linear-gradient(to top, transparent 0%, black 100%), linear-gradient(to right, transparent 0%, black 100%),\n    linear-gradient(to bottom, transparent 0%, black 100%), linear-gradient(to left, transparent 0%, black 100%);\n  -webkit-mask-position: center, top, right, bottom, left;\n  -webkit-mask-size:\n    100% 100%,\n    100% 10px,\n    10px 100%,\n    100% 10px,\n    10px 100%;\n  -webkit-mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;\n  -webkit-mask-composite: source-out, source-over, source-over, source-over;\n  mask-image: linear-gradient(to top, black 0%, black 100%), linear-gradient(to top, transparent 0%, black 100%),\n    linear-gradient(to right, transparent 0%, black 100%), linear-gradient(to bottom, transparent 0%, black 100%),\n    linear-gradient(to left, transparent 0%, black 100%);\n  mask-position: center, top, right, bottom, left;\n  mask-size:\n    100% 100%,\n    100% 10px,\n    10px 100%,\n    100% 10px,\n    10px 100%;\n  mask-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;\n  mask-composite: subtract, add, add, add;\n}\n\n.movieList img {\n  height: 100%;\n  width: 100%;\n  max-height: 30vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
